import React from 'react';
import ReactDOM from 'react-dom';

import NewScratch from './NewScratch';

import styles from './Modal.module.css';

const ScratchModal = (props) => {
  const test = (
    <section className={styles.wrapper}>
      <NewScratch onExit={props.onExit} apiMessage={props.apiMessage}/>
    </section>
  );

  return ReactDOM.createPortal(test, document.querySelector('#scratchModal'));
};

export default ScratchModal;
