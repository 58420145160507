import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Select from 'react-select';

import styles from './SelfEntry.module.css';
import ReCAPTCHA from 'react-google-recaptcha'
import ScratchModal from './Entry/ScratchTimeModal'

const SelfEntry = (props) => {
  const [value, setValue] = useState({
    competitor_id: '',
  });
  const [elapsedValue, setElapsedValue] = useState('');
  const [apiData, setApiData] = useState({ elapsed_time: '', racer_id: '', token: '' });
  //   const [apiData, setApiData] = useState();
  const [nameOptions, setNameOptions] = useState();
  const [timeIsValid, setTimeIsValid] = useState(true);
  const [racerIsValid, setRacerIsValid] = useState(true);
  const [racerSelected, setRacerSelected] = useState(false);
  const recaptchaRef = useRef(null)

  const [scratchModal, setScratchModal] = useState(false);
  const [apiMessage, setApiMessage] = useState('Time Rejected, Please Submit Again');

  /// Regular Expression Validation ///
  const match1 = /^[0-5][0-9][,:.][0-5][0-9]$/; // race under 1 hour
  const match2 = /^[0-9][,:.][0-5][0-9]$/; // race under 10 minutes but why not
  const match3 = /^[0-9]+[,:.][0-5][0-9][,:.][0-5][0-9]$/; // over an hour
  const matchdnf = /^[Dd][Nn][FfSs]$/; // DNF / DNS

  const entry1 = match1.test(elapsedValue);
  const entry2 = match2.test(elapsedValue);
  const entry3 = match3.test(elapsedValue);
  const entry4 = matchdnf.test(elapsedValue);

  const hash = ':     ';
  let names = '';
  let results = '';

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: !racerIsValid && !racerSelected ? '#ffc9c9' : base,

      borderColor: !racerIsValid && !racerSelected ? '#e03131' : '#ced4da',
      fontSize: '1.8rem',
      width: '24rem',
      height: '5rem',
    }),
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/racers', {
        method: 'GET',
        headers: { Accept: 'application/json' },

        credentials: 'include',
      });
      names = await response.json();
      console.log(names);
      setNameOptions(names);
    };
    fetchData();
  }, []);

  const handleDropChange = (e) => {
    setValue({ competitor_id: e.racer_id });
    setRacerSelected(true);
  };

  const handleElapsedChange = (e) => {
    const enteredElapsed = e.target.value;
    setElapsedValue(enteredElapsed);
  };

  const handleSubmitButton = (e) => {
    e.preventDefault();
    console.log(elapsedValue, value);

    if (entry1 || entry2 || entry3 || entry4) {
      setTimeIsValid(true);
    } else {
      setTimeIsValid(false);
    }

    if (racerSelected) {
      setRacerIsValid(true);
    } else {
      setRacerIsValid(false);
    }

    if ((entry1 || entry2 || entry3 || entry4) && racerSelected) {
      setApiData({ elapsed_time: elapsedValue, racer_id: value.competitor_id});
      setValue({
        racer_id: '',
      });
      setElapsedValue('');
      setRacerSelected(false);
    }
  };

  const scratchModalHandler = () => {
    setScratchModal(false);
  };

  const getApiMessage = () => {
    return apiMessage;
  }

  const firstUpdate = useRef(true);
  useEffect(() => {
    //posting data
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const fetchData = async () => {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      console.log(captchaToken)
      // Open the modal now to prevent double clicks on submit if the response is slow
      // But, we wait until the captcha has resolved before opening the modal, because if
      // the user clicks outside the captcha, we don't want the modal there.
      setApiMessage('Submission in Progress. \nReload and resubmit if you don\'t \nget a confirmation')
      setScratchModal(true);
      const response = await fetch('/api/add_scratch_time', {
        method: 'POST',
        body: JSON.stringify({
          elapsed_time: apiData.elapsed_time,
          racer_id: apiData.racer_id,
          token: captchaToken
        }),
        headers: { 'Content-Type': 'application/json' },

        credentials: 'include',
      }).then((res) => {
        return res.json();
      }).then(function(json) {
        setApiMessage(json['message'])
        setScratchModal(true);
      }
      );
    };
    fetchData();
  }, [apiData]);

  const timeInputClasses = timeIsValid ? styles.inputbox : styles.invalid;

  return (
    <div>
      <form className={styles.quick}>
        <h2 className={styles.selfHeader}>Time Entry</h2>
        <div className={styles.quickEntry}>
          <div>
            <Select
              styles={customStyles}
              placeholder={<div>Search skipper or boat...</div>}
              options={nameOptions}
              value={value.racer_id}
              type="text"
              name="racer_id"
              onChange={handleDropChange}
              getOptionLabel={(option) => [
                option.skipper,
                hash,
                option.boat_name,
                hash,
                option.boat_class,
              ]}
              getOptionValue={(option) => option.racer_id}
            />
            {!racerIsValid && (
              <p className={styles.invalidtext}>Must select a racer</p>
            )}
          </div>

          <div className={styles.elapsed}>
            <label className={styles.elapsedLabel}>Elapsed Time</label>
            <input
              className={styles.timeInputClasses}
              type="text"
              name="elapsed"
              value={elapsedValue}
              placeholder="ex:  1.04.25 or 48.15 or DNF"
              onChange={handleElapsedChange}
            />
            {!timeIsValid && (
              <p className={styles.invalidtext}>
                Enter valid format, ex: hh.mm.ss, h.mm.ss, mm.ss, DNF, DNS
              </p>
            )}
          </div>
        </div>
        <button className={styles.btnvalid} onClick={handleSubmitButton}>
          <ion-icon name="add-circle-outline"></ion-icon>Add Result

        </button>
        <h3 className={styles.selfHeader}>Note: If you submit an incorrect time you can resubmit with the correct one</h3>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={'6Le0iQ0hAAAAAN8eDbq_OHiQ2d5UTO0dHpi1EMxl'}
        size="invisible"
      />
      {scratchModal && (
        <ScratchModal
          showModal={scratchModal}
          onExit={scratchModalHandler}
          apiMessage={apiMessage} 
        />
      )}
    </div>
    
  );
};

export default SelfEntry;
