import React, { useEffect } from 'react';

import { useState } from 'react';

import styles from './ImportCheck.module.css';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';

const Close = styled(GrClose)`
  transform: scale(1.5);
  margin: 0;
  // vertical-align: middle;
  // margin-right: 2.4rem;
  color: #dee2e6
  padding: auto;
  margin-left: 1rem;
  cursor: pointer;
  border-style: none;
`;

const NewScratch = (props) => {
  console.log(props.onImport);



  const confirmHandler = () => {
    props.onExit();
  };


  return (
    <div className={styles.imports}>
      <div className={styles.header}>
        <h1>{props.apiMessage}</h1>
      </div>

      <div className={styles.confirm}>
        <button className={styles.btn} onClick={confirmHandler}>
          OK
        </button>
      </div>
    </div>
  );
};

export default NewScratch;