import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.css';
import ImportCheck from './ImportCheck';

const CompModal = (props) => {
  const imports = (
    <section className={styles.wrapper}>
      <ImportCheck
        onExit={props.onExit}
        onImport={props.onImport}
        onMerge={props.onMerge}
      />
    </section>
  );

  return ReactDOM.createPortal(imports, document.querySelector('#importModal'));
};

export default CompModal;
