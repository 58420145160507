import React, { useEffect } from 'react';

import { useState } from 'react';

import styles from './ImportCheck.module.css';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';

const Close = styled(GrClose)`
  transform: scale(1.5);
  margin: 0;
  // vertical-align: middle;
  // margin-right: 2.4rem;
  color: #dee2e6
  padding: auto;
  margin-left: 1rem;
  cursor: pointer;
  border-style: none;
`;

const ImportCheck = (props) => {
  console.log(props.onImport);
  const importedData = props.onImport;

  const [counter, setCounter] = useState(0);

  // counter to trigger import merge refresh //
  const counterHandler = () => {
    setCounter(counter + 1);
    props.onMerge(counter);
    props.onExit();
  };

  const confirmHandler = () => {
    const fetchData = async () => {
      await fetch('/api/import_scratch_times', {
        method: 'POST',
        body: JSON.stringify({}),
      });
      //   .then((res) => res.json())
      //   .then((res) => setImportData(res));

      // // results = await response.json();
      // // // setImportData(results);
      // // console.log(results);
      counterHandler();
    };
    fetchData();

    // props.onExit();
  };

  return (
    <div className={styles.imports}>
      <div className={styles.header}>
        <h1>Mobile Self Entries</h1>
        <button className={styles.icon_btn} onClick={props.onExit}>
          <Close />
        </button>
      </div>

      <div className={styles.results}>
        <table>
          <thead>
            <tr>
              {/* <Delete /> */}
              {/* <span></span> */}

              <th>Boat</th>
              <th>Helm</th>

              <th>Elapsed</th>
            </tr>
          </thead>
          <tbody>
            {importedData.map((results) => (
              <tr>
                <td>{results.boat_name}</td>
                <td>{results.skipper}</td>

                <td>{results.elapsed_time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.confirm}>
        <h3>Do you want to import these entries?</h3>
        <button className={styles.btn} onClick={confirmHandler}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ImportCheck;
